<template>
  <v-main class="pt-4">
    <v-row v-if="loading" class="mx-16">
      <v-col v-for="index in 6" :key="index" cols="2">
        <v-skeleton-loader
            v-bind="skeletonAttrs"
            type="list-item-avatar, list-item-three-line, divider, list-item"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row class="mx-md-16" v-else>
      <v-col :class="$vuetify.breakpoint.mobile ? 'text-center' : ''">
        <h3 class="my-3 secondary-prosk text-center font-weight-bold">
          Lo que nuestros clientes tienen que
decir sobre su experiencia.
        </h3>
      </v-col>
        <hooper :itemsToShow="3" :centerMode="true" @slide="onSlideChange" class="h-50" :settings="hooperSettings">
          <slide v-for="(item, n) in testimonials" :key="n">
            <v-card
             elevation="0"
                width="600"
                height="350"
                :class="[{ 'primary_light': n === currentSlideIndex, 'opacity':  !(n === currentSlideIndex)}, 'border-radius-medium card-with-triangle mb-3']"
            >
              <v-card-title class="d-flex justify-content-center">
                        <p class="m-0 font-size-16 pointer-cursor secondary--text text-center" v-if="item.jobs"
                              @click="goToUserProfile(item.prosker_user)">
                          {{ item.prosker_user.first_name }} {{ item.prosker_user.last_name }} <span v-show="item.jobs.client_user.category_name[0]">|</span> {{ item.jobs.client_user.category_name[0] }}
                        </p>
              </v-card-title>
              <v-card-text class="pb-10 pt-5">
                <p class="ml-3 main_text_color--text font-weight-bold text-h5 text-center">{{ item.title }}</p>
                <p class="ml-3 main_text_color--text text-center">{{ item.content }}</p>
                <v-rating
                      background-color="secondary"
                      half-increments
                      color="secondary"
                      hover
                      readonly
                      dense
                      v-model="item.rating"
                      class="text-center"
                  ></v-rating>
              </v-card-text>
            </v-card>
            <div class="d-flex flex-column align-items-center">
            <v-avatar width="3rem" height="3rem" >
                      <img
                          v-if="item.jobs && item.jobs.client_user.profile_img"
                          :src="item.jobs.client_user.profile_img"
                          class="img-fluid rounded-circle border border-dark avatar-40"
                          alt="user"
                          :class="{  'opacity':  !(n === currentSlideIndex)}"
                      />
                      <img
                          v-else
                          src="@/assets/images/page-img/avatar.png"
                          class="img-fluid rounded-circle avatar-40"
                          alt="user"
                      />
             </v-avatar>
             <p class="green-prosk text-center" :class="{  'opacity':  !(n === currentSlideIndex)}">
                  {{ item.jobs.client_user.first_name }} {{ item.jobs.client_user.last_name }}
             </p>
            </div>
          </slide>
          <hooper-pagination slot="hooper-addons"></hooper-pagination>
        </hooper>
      <!-- </v-col> -->
    </v-row>
  </v-main>
</template>
<script>
import axios from 'axios';
import moment from 'moment/moment';
import { mapGetters } from 'vuex';
// import { Carousel, Slide } from 'vue-carousel';
import goToUserProfile from '@/components/socialvue/mixins/goToUserProfile';
import { Hooper, Slide,
  Pagination as HooperPagination } from 'hooper';
import 'hooper/dist/hooper.css';
export default {
  name: 'Testimonials',
  mixins: [goToUserProfile],
  components: {
    Hooper,
    Slide,
    HooperPagination
  },
  data () {
    return {
      skeletonAttrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2
      },
      userSearchedObject: {},
      loading: false,
      testimonials: [],
      currentSlideIndex: 0,
      hooperSettings: {
                    infiniteScroll: true,
                    centerMode: true,
                    autoPlay: true,
                    playSpeed: 3500,
                    breakpoints: {
                        2400: {
                            itemsToShow: 2
                        },
                        1800: {
                            itemsToShow: 2
                        },
                        1500: {
                            itemsToShow: 3
                        },
                        1100: {
                            itemsToShow: 2.5
                        },
                        0: {
                            itemsToShow: 1.5
                        }
                    }
                }
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  created () {
    this.getReviews();
  },
  methods: {
    getDateFormat (date) {
      const serverDate = new Date(date);
      const myDate = new Date();
      const difference = myDate - serverDate;
      return moment(moment.utc(difference)).format('D');
    },
    getReviews () {
      this.loading = true;
      const url = `${process.env.VUE_APP_BASE_URL}/api/reviews?per_page=8&sortBy=created_at,desc`;
      axios
        .get(url)
        .then((response) => {
          this.testimonials = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.showError(error);
          this.loading = false;
        });
    },
    onSlideChange (payload) {
    this.currentSlideIndex = payload.currentSlide;
  }
  }
};
</script>
<style scoped>
  .round {
    border-radius: 12px;
  }

  .description {
    font-size: 1.1rem;
    color: #59D79D;
    margin-top: -8px;
  }

  .card-with-triangle {
  position: relative;
}

.card-with-triangle::after {
  content: '';
  position: absolute;
  bottom: -15px; /* Adjust to move the triangle */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid #f2f2f2; /* Match the card's background color */
}

.opacity {
  opacity: 0.25;
}

</style>
